import React, { useEffect, useState } from "react";
const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  let scrollProgress = document.getElementById("progress");
  
  const listenToScroll = () => {
    let heightToHidden = 0;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    scrollProgress.style.background = `conic-gradient (#03cc65 ${winScroll}%, #d7d7d7 ${winScroll}%)`;
  
  };
  window.addEventListener("click",()=>{
      document.documentElement.scrollTop = 0
    });
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  return (
    <>
      {isVisible && (
         <div id='progress'>
         <span id='progress-value'>&#x1F81B;</span>
       </div>
      )}
    </>
  );
};





export default GoToTop;