import React from 'react'
import { NavLink } from 'react-router-dom' 
import {TbSquareRoundedLetterY } from 'react-icons/tb'
const Navbar = () => {
  return (
    <nav className="bg-light navbar navbar-expand-lg shadow-sm p-4">
      <div className="container-fluid d-sm-flex flex-column flex-lg-row justify-content-center justify-content-lg-between">
        
        <div className="navbar-brand mb-3 mb-md-0 mb-lg-0">
          <TbSquareRoundedLetterY className='logo-icons color-red'/>
          <span className="ms-1 fw-bold" >yellawebdev</span>
        </div>
       
        <div className="">
          <NavLink to='/' className="link me-5">Home</NavLink>
          <NavLink to='/about' className="link me-5">About</NavLink>
          <NavLink to='/projects' className="link">Projects</NavLink>
        </div>
        
        <div>
          <NavLink to='/contact' className="link">
            <button className='mt-5 m-lg-0 btn-contact   '>Contact me</button>
          </NavLink>
        </div>

      </div>
  </nav>
  )
}

export default Navbar