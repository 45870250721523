 
import BookingTractors from "./assets/portfolioImages/f-tractor.png";
import RestCountries from "./assets/portfolioImages/restcountries.jpg";

const ProjectsData = [
   
  {
    id: "tractor-booking",
    img: BookingTractors,
    name: "Farmers Tractor",
    stack: ["< React.js />", "< Bootstrap v5 />","< MongoDB />" ],
    // live: "https://",
    source: "https://github.com/Madesh0143/f-tractor-booking-React-js",
    description:  
        "A tractor is an agricultural vehicle that has a powerful motor and large wheels. This is commonly used on farmers can book the tractors for a variety of purposes depending on the attachment fitted to it. Because of this, it can help you complete a list of tasks efficiently.",
  },
  {
    id: "rest-countries",
    img: RestCountries,
    name: "Countires API",
    stack: ["< RESTful API />", "< Bootstrap v5 />", "< React.js />"],
    live: 'https://rest-countries-api-yellahub-0431.pages.dev',
    source: "https://github.com/Madesh0143/rest-countries-api",
    description:  
        "Responsive website which integrates with the REST Countries API to pull country data. You can view the list of all countries and find one via search or by filtering regions. Individual country page, enables you to see further details about the country like border countries.",
  },
    
   
];

const InprogressProjectsData = [
   
  {
    id: "servista",
    // img: Servista,
    name: "Servista",
    stack: ["< Flutter />", "< NodeJS />", "< Blockchain />"],
    live: "",
    source: "https://github.com/rimshub/servista",
    description:  
        "A cross-platform app that connects local service providers with customers. The goal is to maximize the income of local professionals while ensuring the security and comfort of customers",
  },

  

];

export default {ProjectsData, InprogressProjectsData}
