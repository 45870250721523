import React from 'react'
import Typewriter from 'typewriter-effect'

function Type() {
  return (
    <Typewriter 
      options={{
        strings: [
          "Frontend Development",
          "Php Development",
          "Web Designing",
          "Software Develope.",

        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 150,
      }}
    />
  );
}

export default Type;
