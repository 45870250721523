import React from "react";
import Card from "react-bootstrap/Card";
import { GoMention } from "react-icons/go";

function AboutCard() {
  return (
    <Card className="quote-card-view ">
      <Card.Body>
        <blockquote className="blockquote  ">
          <p style={{ textAlign: "justify" }}>
            Hi, I'm <span >YELLAPPAN P </span>
            from <span > Dharmapuri.</span>
            <br />I'm a student, I did my postgraduate Master Of Computer Application in  <strong style={{ color:"",fontWeight:800 }} > BHARATHIAR UNIVERSITY</strong>. 
            <br />
            <br />
            Apart from coding, these are some other activities that I enjoy:
          </p>
          <ul>
            
            <li className="about-activity">
              <GoMention /> Traveling
            </li>
            <li className="about-activity">
              <GoMention /> Listening to Music
            </li>
          </ul>
         
         
          <p  style={{ color: "rgb(155 126 17)", padding:5 }}>
            "All repetitive, menial computer tasks can most likely be automated or partially automated with the right code."{" "}
          </p>
          <footer  className=" footer">-- Yellappan</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;