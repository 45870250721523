import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../assets/user.svg";
import Tilt from "react-parallax-tilt";
import {AiFillGithub} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import {TfiEmail} from "react-icons/tfi"

function Home2() {
  return (

    <Container fluid className="home-about-section mt-0 py-3 col-15 " id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description mt-4">
            <h1 style={{ fontSize: "3.5em" }}>
              Let Me <span className="intro "> Introduce </span> Myself
            </h1>
            <p style={{ fontSize: "1.3em" }} className="home-about-body">
              Since I was a kid, I have loved and excelled in programming, mathematics, natural sciences, electronics, and problem solving.
              <br />
              <br />I am experienced in programming languages including
              <i>
                <b > Python, C++, JavaScript</b>
              </i>
              , and SQL dialects including
              <i>
                <b > MongoDB and MySQL</b>.
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b>...placeholder </b> and
                also in areas related to{" "}
                <b>
                  ....placeholder
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with
              <i>
                <b> modern Javascript frameworks</b>
              </i>
              &nbsp; like
              <i>
                <b> Node.js and React.js</b>
              </i>
            </p>
          </Col>
          <Col md={3} className="myAvatar mt-5 py-2">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
              <p  style={{textAlign:'center',padding:20,fontSize:'25px'}}>YELLAPPAN P</p>
            </Tilt>
          </Col>
        </Row>
        <Row>
           <Col md={12} className="home-about-social">
            <h1>Find Me On</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
            <li className="social-icons">
                <a
                  href="yellappan0143@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons">
                  <TfiEmail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/Madesh0143"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons">
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/yellappan0143/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons">
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/m_a_d_e_s_h_0143/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons">
                  <FiInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;