import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import { NavLink } from 'react-router-dom';
import Type from './Type';
import Home2 from './Home2';

const Home = () => {
  return (
    <section className="bg-light p-5 min-vh-100">
      
      <div className='container-fluid'>
       <div className='d-sm-flex align-items-center justify-content-between'>
          <div className='ms-sm-5 ps-sm-5 d-flex flex-column text-center align-items-center align-items-lg-start'>
            <h1 className=''>Hi, I'm Yellappan </h1>
            <div style={{padding: 30, fontWeight: '500' , fontSize: '17px' }} > 
              <Type/>
            </div>
            <NavLink to='/projects'><button className='btn btn-primary   custom-btn ps-5 pe-5  fw-bold mb-5 '>My Work</button></NavLink>
          </div>

          <div>
            <Player
              src='https://assets6.lottiefiles.com/packages/lf20_pwohahvd.json'
              className="player"
              loop
              autoplay
              style={{ maxHeight: '700px', maxWidth: '700px' }}
            />
          </div>
        </div>
      </div>
      <Home2/>
    </section>
  )
}

export default Home