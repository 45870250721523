import React from 'react';
import AboutCard from './AboutCard'
import Techstack from './Techstack'
import resume from '../../assets/resume.pdf'
import Toolstack from './Toolstack';
import { Col, Row } from 'react-bootstrap';
import laptopImg from '../../assets/about.png'
// import Github from './Github'

const About = () => {
  return (
    
    <div className='container-fluid bg-light pt-2 d-flex flex-column align-items-center min-vh-50'>
          <h1 className="project-heading mt-4 py-2" style={{ fontSize: "2.1em" }}>
        About <b>Me</b>
      </h1>
      <Row style={{ justifyContent: "center", padding: "10px" }}>
        <Col  md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",}}>
            
        <AboutCard />
        </Col>
      
      <Col
            md={5}
            style={{ paddingTop: "1px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
      
      </Row>
      <a href={resume} download className="btn btn-primary mt-5 custom-btn fw-bold">Download My Resume</a>
      <h1 className="project-heading mt-5">
        Professional <b >Skill Set</b>
      </h1>

      <Techstack />

      <h1 className="project-heading">
        <b>Tools</b> That I Use
      </h1>
      <Toolstack />
      {/* <Github /> */}
    </div>
  )
}

export default About